<template>
  <validation-observer
    ref="graduationProjectRegisterRoundSaveFormRef"
    #default="{invalid}"
  >
    <b-modal
      id="graduationProjectRegisterRoundSaveModal"
      body-class="position-static"
      size="lg"
      centered
      :title="isCreated ? `Thêm ${resourceName.fullName}` : `Cập nhật ${resourceName.fullName}`"
      no-close-on-backdrop
      @show="onShow"
      @hide="onHide"
    >
      <b-form>
        <b-row>
          <b-col cols="6">
            <b-form-group label-for="trainingSystemId">
              <template v-slot:label>
                Chương trình đào tạo/Bồi dưỡng <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Chương trình đào tạo/Bồi dưỡng"
                rules="required"
              >
                <v-select
                  v-model="targetGraduationProjectRegisterRound.trainingSystemId"
                  :options="trainingSystems"
                  :reduce="option => option.value"
                  @input="onTrainingSystemsChange"
                />
                <b-form-invalid-feedback :state="getElementState(errors)">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group label-for="courseId">
              <template v-slot:label>
                Khóa học <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Khóa học"
                rules="required"
              >
                <v-select
                  v-model="targetGraduationProjectRegisterRound.courseId"
                  :options="courses"
                  :reduce="option => option.value"
                />
                <b-form-invalid-feedback :state="getElementState(errors)">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-form-group label-for="name">
          <template v-slot:label>
            Tên {{ resourceName.fullName }} <span class="text-danger">*</span>
          </template>
          <validation-provider
            #default="{ errors }"
            :name="`Tên ${resourceName.fullName}`"
            rules="required"
          >
            <b-form-input
              id="name"
              v-model="targetGraduationProjectRegisterRound.name"
              name="name"
              :placeholder="`Nhập tên ${resourceName.fullName}`"
              :state="getElementState(errors)"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group label-for="code">
          <template v-slot:label>
            Mã {{ resourceName.fullName }} <span class="text-danger">*</span>
          </template>
          <validation-provider
            #default="{ errors }"
            :name="`Mã ${resourceName.fullName}`"
            rules="required"
          >
            <b-form-input
              id="code"
              v-model="targetGraduationProjectRegisterRound.code"
              name="code"
              :placeholder="`Nhập mã ${resourceName.fullName}`"
              :state="getElementState(errors)"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-row>
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label-for="startDate"
            >
              <template v-slot:label>
                Ngày bắt đầu <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Ngày bắt đầu"
                rules="required"
              >
                <flat-pickr
                  v-model="targetGraduationProjectRegisterRound.startDate"
                  class="form-control"
                  :config="config"
                />
                <b-form-invalid-feedback :state="getElementState(errors)">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label-for="endDate"
            >
              <template v-slot:label>
                Ngày kết thúc <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Ngày kết thúc"
                rules="required"
              >
                <flat-pickr
                  v-model="targetGraduationProjectRegisterRound.endDate"
                  class="form-control"
                  :config="config"
                />
                <b-form-invalid-feedback :state="getElementState(errors)">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group label-for="status">
              <template v-slot:label>
                Trạng thái <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Trạng thái"
                rules="required"
              >
                <v-select
                  v-model="targetGraduationProjectRegisterRound.status"
                  :options="statusOptions"
                  :reduce="option => option.value"
                />
                <b-form-invalid-feedback :state="getElementState(errors)">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
      <template #modal-footer>
        <div class="w-100 d-flex justify-content-end">
          <b-button
            v-show="isCreated"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            :disabled="invalid"
            @click="onSave"
          >
            <span class="text-right">
              <feather-icon icon="CheckIcon" /> Lưu và tiếp tục
            </span>
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            :disabled="invalid"
            @click="onSave('hide')"
          >
            <span class="text-right">
              <feather-icon icon="CheckIcon" /> Lưu lại
            </span>
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-secondary"
            @click="$bvModal.hide('graduationProjectRegisterRoundSaveModal')"
          >
            <span class="text-right">
              <feather-icon icon="XIcon" /> Hủy
            </span>
          </b-button>
        </div>
      </template>
      <b-overlay
        no-wrap
        variant="white"
        spinner-variant="primary"
        blur="0"
        opacity=".75"
        rounded="sm"
        :show="isGraduationProjectRegisterRoundSaveLoading"
      />
    </b-modal>
  </validation-observer>
</template>

<script>
import {
  BButton,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BModal,
  BOverlay,
  BRow,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import vSelect from 'vue-select'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import * as events from 'events'
import { getUser } from '@/auth/utils'
import FlatpickrLanguages from 'flatpickr/dist/l10n'
import flatPickr from 'vue-flatpickr-component'

export default {
  name: 'GraduationProjectRegisterRoundSave',
  components: {
    flatPickr,
    BFormGroup,
    BFormInput,
    BModal,
    BForm,
    BOverlay,
    BFormInvalidFeedback,
    BButton,
    BRow,
    BCol,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    programme: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      isGraduationProjectRegisterRoundSaveLoading: false,
      targetGraduationProjectRegisterRound: {
        name: '',
        code: '',
        trainingSystemId: null,
        status: 1,
        courseId: null,
        startDate: null,
        endDate: null,
      },
      config: {
        enableTime: true,
        altInput: true,
        altFormat: 'd/m/Y H:i',
        dateFormat: 'd/m/Y H:i',
        locale: FlatpickrLanguages.vn,
        time_24hr: true,
      },
      required,
    }
  },
  computed: {
    events() {
      return events
    },
    ...mapGetters({
      statuses: 'graduationProjectRegisterRound/statuses',
      resourceName: 'graduationProjectRegisterRound/resourceName',
      trainingSystems: 'dropdown/trainingSystems',
      courses: 'dropdown/courses',
      courseYears: 'dropdown/courseYears',
    }),
    statusOptions() {
      return this.statuses.map(item => ({ value: item.value, label: item.label }))
    },
    isCreated() {
      return !this.programme
    },
    getOrganizationId() {
      const cUser = getUser()
      return cUser.orgId ? cUser.orgId : 0
    },
  },
  methods: {
    ...mapActions({
      createGraduationProjectRegisterRound: 'graduationProjectRegisterRound/createGraduationProjectRegisterRound',
      updateGraduationProjectRegisterRound: 'graduationProjectRegisterRound/updateGraduationProjectRegisterRound',
      getTrainingSystems: 'dropdown/getTrainingSystems',
      getCourses: 'dropdown/getCourses',
    }),
    async onTrainingSystemsChange(event) {
      this.targetGraduationProjectRegisterRound.courseId = null
      await this.getCourses({ trainingSystemId: event })
    },
    getElementState(errors) {
      return errors.length > 0 ? false : null
    },
    // eslint-disable-next-line consistent-return
    async onShow() {
      this.isGraduationProjectRegisterRoundSaveLoading = true
      try {
        if (this.programme) {
          this.targetGraduationProjectRegisterRound = { ...this.programme }
        }
        if (this.isCreated) {
          await this.getTrainingSystems({ organizationId: this.getOrganizationId })
        } else {
          await Promise.all([
            this.getTrainingSystems({ organizationId: this.getOrganizationId }),
            this.getCourses(),
          ])
        }
        // eslint-disable-next-line no-empty
      } catch (error) {} finally {
        this.isGraduationProjectRegisterRoundSaveLoading = false
      }
    },
    onHide() {
      this.$refs
        .graduationProjectRegisterRoundSaveFormRef
        .reset()
      this.targetGraduationProjectRegisterRound = {
        name: '',
        code: '',
        trainingSystemId: null,
        status: 1,
        courseId: null,
        startDate: null,
        endDate: null,
      }
    },
    async onSave(type = null) {
      const valid = this.$refs
        .graduationProjectRegisterRoundSaveFormRef
        .validate()
      if (valid) {
        this.isGraduationProjectRegisterRoundSaveLoading = true
        try {
          const response = this.isCreated
            ? await this.createGraduationProjectRegisterRound(this.targetGraduationProjectRegisterRound)
            : await this.updateGraduationProjectRegisterRound(this.targetGraduationProjectRegisterRound)
          if (response) {
            const {
              isSuccessful,
              message,
            } = response
            if (isSuccessful) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              })
              if (type === 'hide') {
                this.$bvModal.hide('graduationProjectRegisterRoundSaveModal')
              }
              this.$emit('succeed')
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'XCircleIcon',
                  variant: 'danger',
                },
              })
            }
          }
        } catch (e) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `[${e.code}] ${e.message}`,
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        } finally {
          this.isGraduationProjectRegisterRoundSaveLoading = false
        }
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
